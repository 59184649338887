body {
    background: #e5e5e5;
}

a {
    all: unset;
    cursor: pointer;
}

.css-0 {
    outline: 0;
}

* {
    box-sizing: border-box;
}

.MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}
